import React from "react";
import { Link } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
//import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";

import thirdCaseMockup from "../images/aseguradoramock1.png";
import thirdCaseLogo from "../images/aseguradoralogo.png";
import feraName from "../images/mafer.svg";

import journey from "../images/aseguradoracase.jpg";
import features2 from "../images/aseguradorajourney.svg";
import wire1 from "../images/wireaseguradora.svg";
import wire2 from "../images/aseguradorawire2.svg";
import wire3 from "../images/aseguradorawire3.svg";
//import figma1 from "../images/scene2.png";
import game1 from "../images/aseguradorawire4.svg";


function AseguradoraPage() {
    return (
        <Layout>
            <SEO
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
                title=""
            />

            <section>
                <Link to="/">
                    <div className="relative h-auto w-auto ...">
                        <div>
                            <img
                                alt="Case One"
                                className="md:w-64 object-cover "
                                src={feraName}
                            />
                            {/*   <h2 className=" inline-block p-0.5 px-4 mb-4 leading-tight text-2xl font-semibold tracking-wide text-gray-800 bg-green-200">
          Fernanda Ramírez
        </h2> */}
                        </div>

                        
                        <div className="-m-2">
              <h2 className="inline-block p-0 px-4 mt-4 mb-4 md:text-1xl font-light text-gray-800 text-sm">
                Product Designer 
              </h2>
            </div>

                    </div>
                </Link>

            </section>

            <section className="relative flex items-center justify-center">
                <div className="md:flex-shrink-0">

                    <img
                        alt="Case One"
                        className="h-auto md:w-40 w-24 pb-6"
                        src={thirdCaseLogo}
                    />
                </div>

            </section>

            <section className="relative flex items-center justify-center">

                <p className="block mt-1 text-lg leading-tight text-gray-600 font-thin">Vehicle Assistance App</p>

            </section>

            <section className="relative flex items-center justify-center">

                <p className="block mt-1 text-lg pt-2 leading-tight text-gray-600 font-semibold">ITZAM-DEV</p>

            </section>


            <section>
                <div className="md:flex-shrink-0 md:p-12 p-8 pt-16 md:pt-20">


                    <img
                        alt="Case One"
                        className="h-full w-full object-cover "
                        src={thirdCaseMockup}
                    />
                </div>

                <div className="relative flex items-center justify-center grid grid-cols-3 gap-2 ">
                    <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">Year</div>
                    <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center ">Duration</div>
                    <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">Team</div>
                    <div className=" tracking-wide text-base text-gray-900 font-light text-center">2019-2020</div>
                    <div className=" tracking-wide text-base text-gray-900 font-light text-center ">3 months</div>
                    <div className=" tracking-wide text-base text-gray-900 font-light text-center">Nicolás Vela (UI)</div>


                </div>

                <div className="relative flex items-center justify-center grid grid-cols-1 pt-20 ">
                    <div className="tracking-wide text-2xl text-black font-extrabold text-center ">My role</div>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pt-6 md:px-20 px-2 text-center"> Workflows, Sitemaps, Wireframes, Developers Coordination.</p>
                </div>


                <div className="relative flex items-center justify-center grid grid-cols-1 pt-20 ">
                    <div className="tracking-wide text-2xl text-black font-extrabold text-center ">The context</div>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pt-6 md:px-20 px-2 ">Today, most of the insurance companies in Ecuador do not provide easy ways of requesting vehicle assistance or to report claims for its insurance holders.
</p>
                </div>

                <div className="relative flex items-center justify-center grid grid-cols-1 pt-12 ">
                    <div className="tracking-wide text-2xl text-black font-extrabold text-center pb-6">The process</div>
                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-4 md:w-2/5 w-4/5 bg-purple-100 mt-8">1. Identifying the problem</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">When an insurance holder has an accident or has a problem with his/her car the amount of time it takes for them to contact their broker or assitant is too long and complicated. Most of the times they have just minor inconveniences that can be fixed in a short period of time without paper work or tricky and confusing validations. This can be achieved by implementing an app that eases this process and reduces time.</p>
                    <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                        <img
                            alt="Case One"
                            className="h-full w-full object-cover rounded "
                            src={journey}
                        />

                    </div>

                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pt-4 pb-8 md:px-20 px-2">Right now, one of the main problems is that the user has to follow too many steps in order to get help and the first and only starting point for them to cantact their insurance company is through a call where they are asked questions that sometimes they can not answer because of the stress of the moment or because they are not the direct holders of the insurance (i.e: sons).</p>


                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-4 md:w-3/5 w-auto bg-purple-100 mb-8">2. Identifying the people involved (users) </p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pb-2 md:px-20 px-2">The app will be used by the insurance holders and their direct family.</p>


                    <div className="relative flex items-center justify-center grid md:grid-cols-1 gap-16 grid-cols-1 md:p-8 p-2 ">

                        <div className=" bg-white rounded-lg shadow-md overflow-hidden ">
                            <div className="md:flex">

                                <div className="relative p-8">


                                    <div className="relative flex items-center justify-center grid md:grid-cols-2 gap-8  grid-cols-1">
                                        <div>
                                            <div className="uppercase tracking-wide text-base text-gray-900 font-bold mt-4">Owner of the insurance</div>

                                            <p className="mt-2 font-light text-black">Wants an app that will make requesting help and reporting claims easier.</p>
                                            <p className="mt-2 font-semibold text-semibold mt-4">Specific features:</p>
                                            <p className="mt-2 font-light text-black ">Register every assistance or claim from each member of the family.</p>


                                        </div>

                                        <div>
                                            <div className="uppercase tracking-wide text-base text-gray-900 font-bold mt-4">Insured family</div>

                                            <p className="mt-2 font-light text-black">Want an app that will make requesting help easier.</p>
                                            <p className="mt-2 font-semibold text-semibold mt-4">Specific features:</p>
                                            <p className="mt-2 font-light text-black ">Easy validation.</p>
                                            <p className="mt-1 font-light text-black ">Easy and fast to use.</p>

                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>


                    </div>


                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-6 md:w-3/5 w-auto mb-8 mt-8 bg-purple-100">4. Organizing the features</p>

                    <div className="relative flex items-center justify-center grid md:grid-cols-1 gap-2 grid-cols-1 ">
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={features2}
                            />

                        </div>



                    </div>

                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pt-6 md:px-20 px-2">To make the process fluid, the user will only have to check on the data he/she is being asked through the app in order to get fast mechanical assistance. For bigger accidents or issues, the process requires more steps to cover all the insurance company laws.</p>



                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-6 md:w-3/5 w-auto bg-purple-100 mb-8 mt-8">5. Creating the app</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pt-6 md:px-20 px-2">With the map of the features in mind I satarted creating the wireframes of the platform in figma. It was an iterative process where I considered different possible layouts for the platform and I also implemented different features that can be of great use when using the app.</p>
                    <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                        <img
                            alt="Case One"
                            className="h-full w-full object-cover rounded "
                            src={wire1}
                        />
                    </div>


                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">For exampe: I added the chance for the user to upload pictures of the parts of the car that were damaged both for the interior and the exterior of the car.</p>

                    <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                        <img
                            alt="Case One"
                            className="h-full w-full object-cover rounded "
                            src={wire2}
                        />
                    </div>

                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">And I also added features such as:</p>

                    <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                        <img
                            alt="Case One"
                            className="h-full w-full object-cover rounded "
                            src={wire3}
                        />
                    </div>




                    <div className="tracking-wide text-2xl text-black font-extrabold text-center md:py-6 py-8 ">The result</div>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">As you can see, my role during this project did not involve designing the UI of the app but rather organizing the features and interactions of it. Nicolás, my teammate was the one in charge of it. And all of us, together with the development team came up with a fully functional app that right now, is being used by the insurance holders.</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pt-2 md:px-20 px-2">The final app looks like this. We are still in the fist phase of the app as it will have other features that the company requires.</p>


                    <div className="relative flex items-center justify-center grid grid-cols-1 gap-2 ">
                        <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">
                            <div className="md:flex-shrink-0 md:p-4 p-2 pt-8 md:pt-8">
                                <img
                                    alt="Case One"
                                    className="h-full w-full object-cover rounded "
                                    src={game1}
                                />
                            </div>

                        </div>



                    </div>

                    <div className="tracking-wide text-xl text-gray-700 font-bold text-center py-6">Thank you for reading!</div>

                </div>

            </section>

        </Layout>
    );
}

export default AseguradoraPage;
